import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  BreakPoints,
  buttonMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import { useAppDispatch } from "utils/hooks";
import { VscSettings } from "react-icons/vsc";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import { FaGolfBall, FaCalendarAlt } from "react-icons/fa";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import ReactTooltip from "react-tooltip";
import RoundPresetsModal from "./RoundPresetsModal";
import EventCard from "components/control/events/EventCard";
import RexReservationsLogoIcon from "components/svg/RexReservationsLogoIcon";
import QueueModal from "components/control/simulator-reservations/QueueModal";
import HandicapModal from "./HandicapModal";

const ClubhouseAdmin = () => {
  const { queue } = useFlags();

  const dispatch = useAppDispatch();

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  const isRoundPresetModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.RoundPresets]?.isOpen
  );

  const isQueueModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.QueueModal]?.isOpen
  );

  const isHandicapModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.HandicapModal]?.isOpen
  );

  const setLockerUserHandicapModal = () =>
    dispatch(modalAction({ isOpen: true, modalName: ModalType.HandicapModal }));

  const setQueueTray = () => {
    dispatch(modalAction({ isOpen: true, modalName: ModalType.QueueModal }));
  };
  const setRoundSettingsTray = () => {
    dispatch(modalAction({ isOpen: true, modalName: ModalType.RoundPresets }));
  };

  const listItemClassName =
    "col-span-1 flex flex-col text-center bg-white  dark:bg-zinc-800  dark:text-gray-200 rounded-lg hover:shadow-lg shadow divide-y divide-gray-200";

  return (
    <div
      className={`Events w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      {isRoundPresetModalOpen && (
        <RoundPresetsModal modalName={ModalType.RoundPresets} />
      )}
      {isQueueModalOpen && <QueueModal modalName={ModalType.QueueModal} />}
      {isHandicapModalOpen && (
        <HandicapModal modalName={ModalType.HandicapModal} />
      )}
      <div className="flex flex-col md:flex-row min-h-screen w-full max-w-screen-xl mx-auto mt-8 md:mt-12">
        <div className="mt-8 sm:mx-auto sm:max-w-3xl px-4">
          <motion.ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
            initial={{ x: 0 }}
            animate={{
              transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3,
              },
            }}
          >
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <button
                onClick={() => setLockerUserHandicapModal()}
                className="hover:bg-shadow-sm"
                data-testid="btnOpenLockerUserHandicapTray"
                id="btnOpenLockerUserHandicapTray"
              >
                <EventCard
                  title="Handicap Index"
                  description="Update Locker User's aG Handicap Index and Member Index values"
                  icon={FaGolfBall}
                  enabled={true}
                  testId="updateAgHandicapCard"
                />
              </button>
            </motion.li>
            {queue && (
              <motion.li
                whileTap="tap"
                whileHover="hover"
                variants={buttonMotionVariants}
                className={listItemClassName}
              >
                <button
                  onClick={() => setQueueTray()}
                  className="hover:bg-shadow-sm"
                  data-testid="btnOpenLockerUserHandicapTray"
                  id="btnOpenLockerUserHandicapTray"
                >
                  <EventCard
                    title="Queue"
                    description="Plan future rounds and set players and settings before customers arrive."
                    icon={FaCalendarAlt}
                    enabled={true}
                  />
                </button>
              </motion.li>
            )}
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <button
                onClick={() => setRoundSettingsTray()}
                className="hover:bg-shadow-sm"
                data-testid="btnOpenRoundConfigTray"
                id="btnOpenRoundConfigTray"
              >
                <EventCard
                  title="Round Presets"
                  description="Create default round configurations for reservations"
                  icon={VscSettings}
                  enabled={true}
                />
              </button>
            </motion.li>
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <a
                href="https://app.reservewithrex.com/settings/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className="hover:bg-shadow-sm"
                  data-testid="btnOpenRexReservations"
                  id="btnOpenRexReservations"
                >
                  <div
                    className={`flex-1 flex flex-col p-4 rounded-xl h-full bg-white  dark:bg-zinc-800 "
        }`}
                  >
                    <RexReservationsLogoIcon class="text-2xl mt-2 dark:text-gray-200" />
                    <h3 className="mt-6 text-gray-900 dark:text-gray-200 text-md font-semibold">
                      Rex Admin Site
                    </h3>
                    <dl className="mt-1 flex-grow flex flex-col justify-between mb-2">
                      <dd className="text-gray-500 dark:text-gray-200 text-xs px-2">
                        Manage your reservations
                      </dd>
                      <dt className="sr-only">Event Card</dt>
                    </dl>
                  </div>
                </button>
              </a>
            </motion.li>
          </motion.ul>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      </div>
    </div>
  );
};
export default ClubhouseAdmin;
