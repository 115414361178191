import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { styles } from "./Leagues.styes";
import { MdClose } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch } from "utils/hooks";
import {
  ClubhouseLeagueRoundPlayerDto,
  ClubhouseRoundDto,
  PointPlayerDto,
  PointScorecardsDto,
  RankedPlayerResult,
  RoundLeaderboardPlayerDto,
} from "Models";
import { IoSearchOutline } from "react-icons/io5";
import { leagueScorecardTrayAction } from "ui-modules/nav/navSlice";
import { FormProvider, useForm } from "react-hook-form";
import { leagueModalVariant } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueGroupByFacilityLeagueRoundId } from "modules/leagues/leagueGroupHooks";
import {
  useApplyPointsToScorecards,
  useAutoAddScorecardsToRound,
  useLeagueLeaderboardByLeagueId,
  useLeagueRoundLeaderboardByRoundId,
  useLeagueRoundRankedScorecards,
} from "modules/leagues/leagueScorecardHooks";
import { getLeagueRoundCompetitionId } from "modules/leagues/leagueHelpers";
import { useGetClubhouseLeagueRoundDto } from "modules/leagues/leagueRoundHooks";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import { LeagueHandicapMethod } from "types/enums";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";
import {
  AssignAndRankScorecardsRequestDto,
  ScorecardMatchResponseDto,
} from "ScorecardModels";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import LoadingAnimation from "components/svg/LoadingAnimation";
import RoundMatchPlayScorecard from "./RoundMatchPlayScorecard";
import LeaguePlayerNoRoundScorecard from "./LeaguePlayerNoRoundScorecard";
import LeagueRoundFinalizeConfirm from "./rounds/LeagueRoundFinalizeConfirm";
import LeaguePlayerScorecard from "./LeaguePlayerScorecard";
import LeagueMatchRankedScorecard from "./LeagueMatchRankedScorecard";

type LeagueRoundLeaderboardModalProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leagueRound: ClubhouseRoundDto;
};

const MatchPlayRoundLeaderboardModal: React.FC<LeagueRoundLeaderboardModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { callback, leagueRound } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useSelectedLeague();
  let scorecardIndex = -1;

  const methods = useForm();

  const [
    leagueRoundLeaderboard,
    leagueRoundLeaderboardQueryStatus,
    refreshRoundLeaderboard,
  ] = useLeagueRoundLeaderboardByRoundId(
    currentFacilityId,
    selectedLeague?.id,
    leagueRound?.id
  );

  const [showNet, setShowNet] = useState(
    selectedLeague?.settings.handicapMethod === LeagueHandicapMethod.NET
      ? true
      : false
  );
  const [finalizeLeaderboard, setFinalizeLeaderboard] = useState(false);
  const [addPointsToRound, setAddPointsToRound] = useState(false);
  const [autoAssignScorecards, setAutoAssignScorecards] = useState(false);

  const [
    leagueRoundRankedScorecards,
    leagueRoundRankedScorecardsQueryStatus,
    refreshLeagueRoundRankedScorecards,
  ] = useLeagueRoundRankedScorecards(
    currentFacilityId,
    selectedLeague?.id,
    leagueRound?.id
  );

  const [, , refreshLeagueLeaderboard] = useLeagueLeaderboardByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    selectedLeague.id,
    leagueRound?.id
  );

  const [leagueRoster, ,] = useLeagueGroupByFacilityLeagueRoundId(
    currentFacilityId,
    selectedLeague?.id,
    leagueRound?.id
  );

  const [, applyPointsToScorecard] = useApplyPointsToScorecards(
    currentFacilityId,
    selectedLeague?.id,
    leagueRound?.id
  );

  const [
    autoAddScorecardToRoundQueryState,
    autoAddScorecardToRound,
  ] = useAutoAddScorecardsToRound(
    currentFacilityId,
    selectedLeague?.id,
    leagueRound?.id
  );

  const selectedLeagueRoundCompetitionId = getLeagueRoundCompetitionId(
    leagueRoundRankedScorecards?.scores
  );

  const selectedLeagueRoundIsActive =
    selectedLeagueRoundCompetitionId &&
    leagueRoundRankedScorecards?.scores[selectedLeagueRoundCompetitionId]
      ?.players.length > 0
      ? true
      : false;

  const playersWithNoRoundScorecard = leagueRoster.filter(
    (player: ClubhouseLeagueRoundPlayerDto) => {
      return !leagueRoundLeaderboard?.players?.find(
        (currentPlayer: RoundLeaderboardPlayerDto) => {
          return player.playerId === currentPlayer.playerGuid;
        }
      );
    }
  );

  const setScorecardTray = (value: boolean) => {
    disableBackgroundScroll();
    dispatch(leagueScorecardTrayAction({ isOpen: true }));
  };

  const handleLeagueRoundLeaderboardSubmit = async (data: PointPlayerDto[]) => {
    let payload = {} as PointScorecardsDto;
    const players = methods.getValues("players");

    payload.roundLeaderboardId = leagueRoundRankedScorecards?.roundLeaderboardId?.toString();
    payload.roundId = leagueRoundRankedScorecards?.roundId?.toString();
    payload.scores = {
      [selectedLeagueRoundCompetitionId]: {
        players: players?.map((player: PointPlayerDto) => {
          return {
            playerRoundLeaderBoardId: player.playerRoundLeaderBoardId,
            points: player.points,
          };
        }),
        teams: [],
      },
    };

    let response = await applyPointsToScorecard(payload);

    if (isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Round leaderboard updated successfully.",
        GenericNotificationType.SUCCESS
      );
      setAddPointsToRound(false);
      refreshLeagueRoundRankedScorecards();
      refreshLeagueRound();
    } else {
      showCustomNotification(
        response?.body?.detail,
        GenericNotificationType.ERROR
      );
    }
  };

  const finalizeLeaderboardCallback = () => {
    setFinalizeLeaderboard(false);
    refreshLeagueLeaderboard();
  };

  const LeagueModalHeader = () => {
    const handleCloseModal = async () => {
      dispatch(
        setSelectedRoundId({
          selectedLeagueRoundId: "",
        })
      );
      callback && callback(true);
    };

    const handleNetGrossToggle = (
      e: React.MouseEvent<HTMLButtonElement>,
      netScore: boolean
    ) => {
      e.preventDefault();
      setShowNet(netScore);
    };

    return (
      <div className={styles.modalHeader} id="modalHeader">
        <div className={`${styles.modalHeading} flex-col`}>
          <div className="flex w-full">
            <button
              type="button"
              tabIndex={-1}
              className={styles.modalCloseButton}
              onClick={(e) => {
                e.preventDefault();
                handleCloseModal();
              }}
            >
              <IoMdClose className="size-4" />
            </button>
            {leagueRound?.name} Leaderboard
          </div>
          <div className="flex w-full text-xs text-gray-400 dark:text-gray-50 ml-4 pl-6 pt-1">
            League: {selectedLeague?.name}
          </div>
        </div>
        <div className={styles.modalToggleContainerOuter}>
          <div>
            <button
              className={styles.modalSearchButton}
              onClick={() => setScorecardTray(true)}
            >
              <IoSearchOutline className="size-4 font-normal" />
              <span className="inline md:hidden poppins pr-2">Search</span>
            </button>
          </div>
          <div className={`${styles.modalToggleContainer}`}>
            <button
              className={`${showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="net"
              onClick={(e) => handleNetGrossToggle(e, true)}
            >
              <span className="">Net</span>
            </button>
            <button
              className={` ${!showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="gross"
              onClick={(e) => handleNetGrossToggle(e, false)}
            >
              <span className="">Gross</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const LeagueModalLoading = () => {
    return (
      <div className={styles.modalLoading}>
        <SpinnerAnimation color={"#f2f2e6"} />
      </div>
    );
  };

  const LeagueModalNoResults = () => {
    return (
      <div className={styles.modalNoResults}>
        <MdClose className={styles.noLeaguesIcon} />
        There are no scorecards for this leaderboard yet. Use the
        <button
          onClick={() => setScorecardTray(true)}
          className="border-b border-blue-400 mx-1"
        >
          Scorecard Lookup
        </button>
        to add scorecards.
      </div>
    );
  };

  useEffect(() => {
    if (!autoAssignScorecards) {
      autoAddScorecardToRound({} as AssignAndRankScorecardsRequestDto);
      setAutoAssignScorecards(true);
      refreshRoundLeaderboard();
    }
  }, [autoAddScorecardToRound, autoAssignScorecards, refreshRoundLeaderboard]);

  return (
    <>
      {finalizeLeaderboard && (
        <LeagueRoundFinalizeConfirm
          modalName={`FinalizeRound`}
          bodyText={`Are you sure you want to finalize ${leagueRound?.name}?`}
          confirmButtonText="Yes, Finalize"
          cancelButtonText="Cancel"
          callback={() => finalizeLeaderboardCallback()}
        />
      )}
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="w-full h-dvh"
        variants={leagueModalVariant}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleLeagueRoundLeaderboardSubmit)}
          >
            <LeagueModalHeader />
            {autoAddScorecardToRoundQueryState?.isFinished ? (
              <div className={styles.modalBody} id="modalBody" tabIndex={0}>
                {leagueRoundRankedScorecardsQueryStatus?.isFinished &&
                leagueRoundLeaderboardQueryStatus?.isFinished ? (
                  leagueRoundRankedScorecards && leagueRoundLeaderboard ? (
                    <div className="w-full">
                      <div
                        className={styles.modalResultsHeaderRow}
                        key={`matchPlayRoundLeaderboardModal-${leagueRoundLeaderboard.roundLeaderboardId}`}
                      >
                        <div className="col-span-4">
                          <div className="hover:cursor-pointer">
                            Match/Player
                          </div>
                        </div>
                        <div className="hidden md:inline-block col-span-2">
                          Result
                        </div>
                        <div className="col-span-1 text-left">
                          <div className="hover:cursor-pointer">Won</div>
                        </div>
                        <div className="col-span-1">
                          <div className="hover:cursor-pointer">Tied</div>
                        </div>
                        <div className="col-span-2">
                          <div className="hover:cursor-pointer">Points</div>
                        </div>
                        <div className="col-span-1">
                          <div className="hover:cursor-pointer">Handicap</div>
                        </div>
                        <div className="col-span-1"></div>
                      </div>
                      <div className="max-h-[500px] overflow-y-auto">
                        {!selectedLeagueRoundIsActive &&
                          leagueRoundLeaderboard?.players?.map(
                            (player: RoundLeaderboardPlayerDto, index) => (
                              <LeaguePlayerScorecard
                                leaderboardPlayer={player}
                                key={`${player?.playerGuid}-scorecard`}
                                finalizeScorecards={addPointsToRound}
                                index={index}
                                showNetScore={showNet}
                                roundPar={
                                  leagueRoundLeaderboard?.selectedHolesPar
                                }
                              />
                            )
                          )}
                        {selectedLeagueRoundIsActive &&
                          leagueRoundLeaderboard?.matches &&
                          leagueRoundLeaderboard?.matches.map(
                            (match: ScorecardMatchResponseDto, index) => (
                              <>
                                <RoundMatchPlayScorecard
                                  matchResults={match}
                                  showNetScore={showNet}
                                  index={index}
                                />
                                {leagueRoundLeaderboard?.players
                                  .filter(
                                    (player: RoundLeaderboardPlayerDto) =>
                                      player.roundPlayerId ===
                                        match?.roundParticipant1Id ||
                                      player.roundPlayerId ===
                                        match?.roundParticipant2Id
                                  )
                                  .sort(
                                    (
                                      a: RoundLeaderboardPlayerDto,
                                      b: RoundLeaderboardPlayerDto
                                    ) =>
                                      a.currentRound?.place -
                                      b.currentRound?.place
                                  )
                                  .map(
                                    (
                                      player: RoundLeaderboardPlayerDto,
                                      playerIndex
                                    ) => (
                                      <LeagueMatchRankedScorecard
                                        playerRankedScorecard={leagueRoundRankedScorecards?.scores[
                                          selectedLeagueRoundCompetitionId
                                        ]?.players.find(
                                          (rankedPlayers: RankedPlayerResult) =>
                                            rankedPlayers.playerGuid ===
                                            player.playerGuid
                                        )}
                                        playerRoundLeaderboard={Array(player)}
                                        opponentRoundLeaderboard={Array(
                                          leagueRoundLeaderboard?.players.filter(
                                            (
                                              opponent: RoundLeaderboardPlayerDto
                                            ) =>
                                              opponent.roundMatchId ===
                                                player.roundMatchId &&
                                              opponent.playerGuid !==
                                                player.playerGuid
                                          )[0]
                                        )}
                                        key={`${player?.playerGuid}-scorecard`}
                                        finalizeScorecards={addPointsToRound}
                                        index={++scorecardIndex}
                                        showNetScore={showNet}
                                        roundPar={
                                          leagueRoundLeaderboard?.selectedHolesPar
                                        }
                                        callback={() => {
                                          refreshRoundLeaderboard();
                                          refreshLeagueRoundRankedScorecards();
                                        }}
                                        matchResults={match}
                                        participantNumber={
                                          player.roundPlayerId ===
                                          match?.roundParticipant1Id
                                            ? 1
                                            : 2
                                        }
                                      />
                                    )
                                  )}
                              </>
                            )
                          )}

                        {playersWithNoRoundScorecard.map((player) => (
                          <LeaguePlayerNoRoundScorecard
                            leaderboardPlayer={player}
                            key={`${player?.playerId}-scorecard`}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <LeagueModalNoResults />
                  )
                ) : (
                  <LeagueModalLoading />
                )}
              </div>
            ) : (
              <LoadingAnimation
                loadingText={"Searching for round scorecards..."}
              />
            )}
            <div
              className={`${
                playersWithNoRoundScorecard?.length === 0 && "hidden"
              } px-6 text-xs text-gray-300`}
            >
              <small>
                -- designates players in the round who have no scorecard
                assigned
              </small>
            </div>
            <div className={`${styles.modalFooter} mt-8`} id="modalFooter">
              {addPointsToRound && (
                <>
                  <button
                    className={styles.createButton}
                    type="submit"
                    tabIndex={100}
                  >
                    Save
                  </button>
                  <button
                    className={`${styles.leagueCancelButton}`}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddPointsToRound(!addPointsToRound);
                    }}
                    tabIndex={99}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </form>
        </FormProvider>
        <div className={`${styles.modalFooter}`}>
          {!addPointsToRound && (
            <>
              <button
                className={`${styles.finalizeRoundButton} w-auto mr-2 disabled:cursor-not-allowed
                }`}
                onClick={() => setFinalizeLeaderboard(!finalizeLeaderboard)}
                type="button"
                disabled={leagueRound?.isCompleted}
              >
                {leagueRound?.isCompleted
                  ? "Round has been Completed"
                  : "Finalize Round"}
              </button>
              <button
                className={`${styles.leagueCancelButton} w-auto disabled:hover:bg-brandGreyText disabled:cursor-not-allowed `}
                onClick={() => setAddPointsToRound(!addPointsToRound)}
                type="button"
                disabled={leagueRound?.isCompleted}
              >
                {leagueRound?.isScored ? "Edit Points" : "Add Points"}
              </button>
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default MatchPlayRoundLeaderboardModal;
