import Modal from "components/shared/Modal";
import Row from "components/ui/layout/Row";
import { useScorecardImageState } from "modules/scorecard/scorecardHooks";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";

const ScorecardModal: React.FC<ScorecardModalProps> = (props) => {
  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: props.simulatorId })
  );
  const [scorecardImageState] = useScorecardImageState(simulator.id);

  return (
    <Modal
      modalName={props.modalName}
      disableContentScrolling={true}
      hideFooter={true}
      hideHeader={false}
      modalSize="w-full h-full lg:w-5/6 2xl:w-2/3"
      title={simulator?.name + " scorecard"}
      body={
        <Row id="scorecard" cssClasses="text-center">
          {
            <img
              src={`data:image/jpeg;base64,${scorecardImageState}`}
              alt="Current Scorecard"
            />
          }
        </Row>
      }
    />
  );
};

type ScorecardModalProps = {
  title?: string;
  modalName: string;
  simulatorId: string;
};

ScorecardModal.defaultProps = {
  title: "Get Scorecard",
};

export default ScorecardModal;
