import { useState } from "react";
import { LockerUser } from "Models";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { FormRow } from "components/ui/form";
import { Gender, TeeType } from "types/enums";
import { styles } from "./RosterAndGroups.styles";
import { customStyles } from "utils/ui/uiHelpers";
import {
  roundConfigEnumsAsReactSelectOptions,
  titleCase,
} from "utils/enums/enumHelper";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import { parsePhoneNumber } from "libphonenumber-js";
import { createLockerUser } from "modules/leagues/leagueRosterQueries";
import Select from "react-select";
import toast from "react-hot-toast";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Label from "components/ui/form/Label";

const CreateLeaugeRosterLockerUser: React.FC<CreateLeaugeRosterLockerUserProps> = (
  props
) => {
  const {
    showHideComponentCallback,
    returnLockerUserGuidCallback,
    returnGuidOnly,
  } = props;
  const [lockerUser, setLockerUser] = useState<LockerUser>({
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    email: "",
    countryCode: "1",
    teeType: TeeType.BLACK,
    gender: Gender.MALE,
    handicap: 0,
  });
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const handicapMax = lockerUser.gender === Gender.FEMALE ? "36.4" : "40.4";

  const handleCreateLockerUser = async (data: LockerUser) => {
    const response = createLockerUser(data);

    response.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        toast.success(data.firstName + " " + data.lastName + " user created", {
          id: `toast-handleCreateLockerUser${uuidv4()}`,
        });

        returnGuidOnly
          ? returnLockerUserGuidCallback((res.data as LockerUser).userId)
          : returnLockerUserGuidCallback(res.data);
        showHideComponentCallback(false);
      } else {
        toast.error(titleCase(res.data), {
          id: `toast-handleCreateLockerUser${uuidv4()}`,
        });
        setErrors(res.data);
        console.error(res.data);
      }
    });
  };

  const handlePhoneNumberChange = (value: string) => {
    if (value.length > 8) {
      let phone = parsePhoneNumber(value);

      setLockerUser({
        ...lockerUser,
        countryCode: phone.countryCallingCode,
      });
      setLockerUser({ ...lockerUser, phoneNumber: phone.nationalNumber });
    }
  };

  return (
    <div className="space-y-6" data-testid="addLockerUserForm">
      <motion.div className={styles.createLockerUserContainer}>
        <div className="flex flex-row pt-2">
          <div className="flex flex-col w-1/2 mr-2">
            <Label
              name="First Name"
              cssClasses={styles.lockerUserInputFormLabel}
            />
            <input
              name="firstName"
              className={
                errors?.toLowerCase().includes("firstname")
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              autoFocus={true}
              tabIndex={5}
              onBlur={(e) => {
                setLockerUser({
                  ...lockerUser,
                  firstName: e.target.value.trim(),
                });
              }}
            />
          </div>
          <div className="flex flex-col w-1/2">
            <Label
              name="Last Name"
              cssClasses={styles.lockerUserInputFormLabel}
            />
            <input
              name="lastName"
              className={
                errors?.toLowerCase().includes("lastname")
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={6}
              onBlur={(e) => {
                setLockerUser({
                  ...lockerUser,
                  lastName: e.target.value.trim(),
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex flex-col w-1/2 mr-2">
            <Label
              name="Username"
              cssClasses={styles.lockerUserInputFormLabel}
              tooltipText="Username can only be letters, numbers, underscores (_) and spaces"
            />
            <input
              name="userName"
              className={
                errors?.toLowerCase().includes("username")
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={6}
              onKeyUp={(e) => {
                setLockerUser({
                  ...lockerUser,
                  userName: e.currentTarget.value.replace(
                    /[^A-Za-z0-9 _]/g,
                    ""
                  ),
                });
              }}
              onBlur={(e) => {
                setLockerUser({
                  ...lockerUser,
                  userName: e.target.value.trim(),
                });
              }}
            />
          </div>
          <div className="flex flex-col w-1/2">
            <Label name="Email" cssClasses={styles.lockerUserInputFormLabel} />
            <input
              name="email"
              className={
                errors?.toLowerCase().includes("email")
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={7}
              onBlur={(e) => {
                setLockerUser({ ...lockerUser, email: e.target.value.trim() });
              }}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex flex-col w-1/2 mr-2">
            <Label name="Phone" cssClasses={styles.lockerUserInputFormLabel} />
            <PhoneInput
              name="phoneNumber"
              international
              defaultValue={lockerUser.phoneNumber}
              defaultCountry="US"
              onChange={(e) => {
                handlePhoneNumberChange(e || "");
              }}
              tabIndex={8}
              className={
                errors?.toLowerCase().includes("phone")
                  ? styles.lockerUserInputFormError
                  : "text-xs rounded-xl border-gray-300 border bg-white dark:text-white dark:bg-zinc-900"
              }
            />
          </div>
          <div className="flex flex-col w-1/2">
            <Label
              name="Handicap Index"
              cssClasses={styles.lockerUserInputFormLabel}
            />
            <input
              id="handicap"
              name="handicap"
              className="border border-gray-300 text-sm rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              step="0.1"
              min="-10"
              max={handicapMax}
              defaultValue={lockerUser.handicap || 25}
              onBlur={(event) =>
                setLockerUser({
                  ...lockerUser,
                  handicap: parseFloat(event.target.value),
                })
              }
              tabIndex={9}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex flex-col w-1/2 mr-2">
            <Label name="Gender" cssClasses={styles.lockerUserInputFormLabel} />
            <Select
              isSearchable={false}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.gender}
              onChange={(val: { value: unknown; label: string } | null) =>
                val &&
                setLockerUser({ ...lockerUser, gender: val.value as Gender })
              }
              value={roundConfigEnumsAsReactSelectOptions.gender.find(
                (c) => c.value === lockerUser.gender
              )}
              styles={customStyles}
              tabIndex={10}
              name="gender"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <Label
              name="TeeType"
              cssClasses={styles.lockerUserInputFormLabel}
            />
            <Select
              isSearchable={false}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions?.teeType}
              onChange={(val: { value: unknown; label: string } | null) =>
                val &&
                setLockerUser({ ...lockerUser, teeType: val.value as TeeType })
              }
              value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                (c) => c.value === lockerUser.teeType
              )}
              styles={customStyles}
              tabIndex={11}
              name="teeType"
            />
          </div>
        </div>
        <FormRow>
          <div className="flex justify-start mb-2">
            <div
              onClick={() => handleCreateLockerUser(lockerUser)}
              className={styles.lockerUserInputFormButton}
              tabIndex={12}
            >
              Create Locker User
            </div>
            <div
              onClick={() => showHideComponentCallback(false)}
              className={styles.lockerUserInputFormButtonAlt}
              tabIndex={13}
            >
              Cancel
            </div>
          </div>
        </FormRow>
      </motion.div>
    </div>
  );
};

export default CreateLeaugeRosterLockerUser;

type CreateLeaugeRosterLockerUserProps = {
  showHideComponentCallback: any;
  returnLockerUserGuidCallback: any;
  returnGuidOnly?: boolean;
};
