import { SimulatorReservation } from "Models";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useDeleteSimulatorReservations,
  useSendSimulatorReservationToSim,
} from "modules/simulator-reservations/simulatorReservationHooks";
import { useEffect } from "react";
import {
  queueItemTrayAction,
  queueTrayAction,
  simulatorReservationTrayAction,
} from "ui-modules/nav/navSlice";
import {
  roundConfigEnumsAsReactSelectOptions,
  titleCase,
} from "utils/enums/enumHelper";
import { useAppDispatch } from "utils/hooks";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { styles } from "./SimulatorReservation.styles";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import { useStartUnityGame } from "modules/round/roundHooks";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import { simulatorStateIsActive } from "modules/simulator-state/simulatorStateHelpers";
import { VscSend } from "react-icons/vsc";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { RoundType, UnityGames } from "types/enums";
import toast from "react-hot-toast";
import { DateTime } from "luxon";

type OwnProps = {
  simulatorReservation: SimulatorReservation;
};

const SimulatorReservationDetails: React.FC<OwnProps> = (props) => {
  const dispatch = useAppDispatch();
  const { simulatorReservation } = props;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const roundInfo = simulatorReservation.roundConfiguration?.roundInfo;

  const [simulatorState] = useSimulatorState(simulatorReservation.simulatorId);
  const stateIsActive = simulatorStateIsActive(simulatorState);

  const [
    deleteSimulatorReservationState,
    deleteSimulatorReservation,
  ] = useDeleteSimulatorReservations(
    simulatorReservation.id,
    currentFacilityId
  );
  const [
    sendSimulatorReservationState,
    sendSimulatorReservation,
  ] = useSendSimulatorReservationToSim(simulatorReservation.id);

  const [, startUnityGame] = useStartUnityGame(
    currentFacilityId,
    simulatorReservation.simulatorId
  );

  const handleDeleteSimulatorReservation = () => {
    if (window.confirm("Do you want to delete this reservation?")) {
      deleteSimulatorReservation();
      pendoTrackBasicEvent(
        ClubhouseEvents.DELETE_RESERVATION,
        simulatorReservation.id
      );
    }
  };

  const handleEditSimulatorReservation = (queueItemId: string) => {
    dispatch(
      setSelectedQueueItem({
        selectedQueueItem: queueItemId,
      })
    );
    dispatch(queueItemTrayAction({ isOpen: true }));
    dispatch(queueTrayAction({ isOpen: true }));
    pendoTrackBasicEvent(
      ClubhouseEvents.EDIT_RESERVATION,
      simulatorReservation.id
    );
  };

  const handleSendSimulatorReservation = (reservationId: string) => {
    if (
      simulatorReservation?.roundConfiguration?.roundType === RoundType.GAME
    ) {
      let duration = DateTime.fromISO(simulatorReservation?.endTime)
        .diff(DateTime.fromISO(simulatorReservation.startTime))
        .as("minutes");
      let response = startUnityGame({
        players: roundInfo?.players
          ? roundInfo?.players.map((player) => ({
              id: player.id,
              username: player.username,
              userGuid: player.userGuid,
              displayName: player.displayName || player.username,
            }))
          : [],
        game: roundInfo?.game || UnityGames.GAME_MINIGOLF_NONE,
        time: duration,
        useMultiSport: roundInfo?.multisport === true ? true : false,
      });
      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          toast.success("Game sent to sim");
        } else {
          toast.error("Failed to send game to sim");
        }
      });
    } else {
      let response = sendSimulatorReservation(reservationId);
      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          toast.success("Round started");
        } else {
          if (res?.status === 400) {
            res?.text
              ? toast.error(res?.text, { duration: 10000 })
              : toast.error("Failed to start round");
          } else {
            toast.error("Failed to start round");
          }
        }
      });
    }

    pendoTrackBasicEvent(ClubhouseEvents.SEND_TO_SIM, simulatorReservation.id);
  };

  useEffect(() => {
    if (deleteSimulatorReservationState.isFinished) {
      showCustomNotification(
        "Simulator reservation deleted",
        GenericNotificationType.SUCCESS
      );
    }
  }, [deleteSimulatorReservationState.isFinished]);

  useEffect(() => {
    if (sendSimulatorReservationState) {
      if (sendSimulatorReservationState.isFinished) {
        showCustomNotification(
          "Reservation has been sent to sim",
          GenericNotificationType.SUCCESS
        );
        dispatch(simulatorReservationTrayAction({ isOpen: false }));
      }
      if (sendSimulatorReservationState.isPending) {
        showCustomNotification("Reservation is loading...");
      }
    }
  }, [sendSimulatorReservationState, dispatch]);

  const booleanList = {
    options: [
      { value: false, label: "Off" },
      { value: true, label: "On" },
    ],
  };

  return simulatorReservation?.roundConfiguration?.roundType ===
    RoundType.RANGE ? (
    <table className="table-auto w-full text-xs poppins">
      <tbody>
        <tr className={styles.queuePreviewRow}>
          <td>Range:</td>
          <td className={styles.queuePreviewRightColumn}>
            {titleCase(
              roundConfigEnumsAsReactSelectOptions.ranges.find(
                (option) =>
                  option.value ===
                  simulatorReservation?.roundConfiguration?.range
              )?.label || ""
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="align-right pt-6">
            <button
              className="w-12 text-left text-xs pt-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                handleEditSimulatorReservation(simulatorReservation.id)
              }
            >
              <FiEdit2 className="text-sm size-5 hover:text-gray-500 dark:fill-white dark:bg-white dark:text-white" />
            </button>
            <button
              className="w-12 pt-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleDeleteSimulatorReservation()}
            >
              <FiTrash className="text-sm size-5 hover:text-gray-500" />
            </button>
            <button
              disabled={stateIsActive ? false : true}
              className="send-sim-reservation float-right text-xs p-2 bg-brandYellow hover:bg-brandGreen rounded-xl disabled:text-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                handleSendSimulatorReservation(simulatorReservation.id)
              }
            >
              <VscSend className="text-sm size-5 text-black dark:text-white hover:text-white" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <table className="table-auto w-full text-xs poppins">
      <tbody>
        <tr className={styles.queuePreviewRow}>
          <td>Auto Drop:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              booleanList.options.find(
                (option) => option.value === roundInfo?.autoDrop
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Auto Concede:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.autoConcede.find(
                (option) => option.value === roundInfo?.autoConcede
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Auto Gimme:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.autoGimme.find(
                (option) => option.value === roundInfo?.autoGimmes
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Gimme Distance:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.gimmeDistance.find(
                (option) => option.value === roundInfo?.gimmeDistance
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Handicap:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.handicapMode.find(
                (option) => option.value === roundInfo?.handicapMode
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Mulligans:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              booleanList.options.find(
                (option) => option.value === roundInfo?.mulligans
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Pin Type:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.pinType.find(
                (option) => option.value === roundInfo?.pinType
              )?.label
            }
          </td>
        </tr>
        <tr className={styles.queuePreviewRow}>
          <td>Scoring Type:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.scoringType.find(
                (option) => option.value === roundInfo?.scoringType
              )?.label
            }
          </td>
        </tr>
        <tr className="dark:text-white">
          <td>Wind Speed:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.windSpeed.find(
                (option) => option.value === roundInfo?.windSpeed
              )?.label
            }
          </td>
        </tr>

        <tr>
          <td colSpan={2} className="pt-2 mt-2 pb-1 text-center text-gray-400">
            {!stateIsActive ? (
              <span>
                Turn on sim to enable Send to Sim, or Edit this round to select
                a different sim
              </span>
            ) : null}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="align-right">
            <button
              className="w-12 text-left text-xs pt-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                handleEditSimulatorReservation(simulatorReservation.id)
              }
            >
              <FiEdit2 className="text-sm size-5 hover:text-gray-500 dark:text-gray-50" />
            </button>
            <button
              className="w-12 pt-2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleDeleteSimulatorReservation()}
            >
              <FiTrash className="text-sm size-5 hover:text-gray-500 dark:text-gray-50" />
            </button>
            <button
              disabled={stateIsActive ? false : true}
              className="send-sim-reservation float-right text-xs p-2 bg-brandYellow hover:bg-brandGreen rounded-xl disabled:text-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                handleSendSimulatorReservation(simulatorReservation.id)
              }
            >
              <VscSend className="text-sm size-5 text-black dark:text-white hover:text-white" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default SimulatorReservationDetails;
