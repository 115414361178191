import { styles } from "./Leagues.styes";
import { AnimatePresence, motion } from "framer-motion";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import {
  BreakPoints,
  containerMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import LeagueCard from "./LeagueCard";
import { useCallback, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import LeagueFilter from "./LeagueFilter";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useAppDispatch } from "utils/hooks";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";
import { createLeagueTrayAction } from "ui-modules/nav/navSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeaguesByFacilityId } from "modules/leagues/leagueHooks";
import { MdClose } from "react-icons/md";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import { ModalType } from "utils/modals/modalHelpers";
import HandicapModal from "components/admin/HandicapModal";
import LeagueLeaderboardModal from "./LeagueLeaderboardModal";

const Leagues = () => {
  const dispatch = useAppDispatch();

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  const [leagueTypeFilter, setLeagueTypeFilter] = useState([1, 2]);
  const [leagues, leagueQueryStatus] = useLeaguesByFacilityId(
    currentFacilityId
  );

  const isCreateLeagueTrayOpen = useSelector(
    (state: RootState) => state.nav?.createLeagueTray?.isOpen
  );

  const isLeagueLeaderboardModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.LeagueLeaderboard]?.isOpen
  );

  const isHandicapModalOpen = useSelector(
    (state: RootState) =>
      state.modals?.modalSettings[ModalType.HandicapModal]?.isOpen
  );

  const handleCreateLeagueTrayClosed = useCallback(() => {
    disableBackgroundScroll();
    dispatch(createLeagueTrayAction({ isOpen: !isCreateLeagueTrayOpen }));
  }, [dispatch, isCreateLeagueTrayOpen]);

  useEffect(() => {
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: "",
      })
    );
  }, [dispatch]);

  return (
    <>
      {isHandicapModalOpen && (
        <HandicapModal modalName={ModalType.HandicapModal} />
      )}
      {isLeagueLeaderboardModalOpen && (
        <LeagueLeaderboardModal modalName={ModalType.LeagueLeaderboard} />
      )}
      <div
        className={`@container/leagues leagues w-full ${
          isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
        }`}
      >
        <div className={styles.innerContainer}>
          <AnimatePresence>
            <motion.div
              className={styles.motionContainer}
              key="leagueCards"
              variants={containerMotionVariants}
              initial="hidden"
              animate="show"
            >
              <div className={styles.filterContainer}>
                <div className={styles.filterInnerContainerLeft}>
                  <LeagueFilter
                    leagueTypeFilter={leagueTypeFilter}
                    callback={setLeagueTypeFilter}
                  />
                </div>
                <div className={styles.filterInnerContainerRight}>
                  <button
                    type="button"
                    className={styles.createButton}
                    onClick={() => handleCreateLeagueTrayClosed()}
                  >
                    Create League
                  </button>
                </div>
              </div>
              {leagueQueryStatus.isPending ? (
                <div className="flex items-center h-3/4 w-full">
                  <SpinnerAnimation color={"#f2f2e6"} />
                </div>
              ) : (
                <>
                  {leagues.length === 0 && (
                    <div className={styles.noLeaguesAvailable}>
                      <MdClose className={styles.noLeaguesIcon} />
                      There are no current leagues available. Click "Create
                      League +" to create your first league.
                    </div>
                  )}
                  <div className={styles.leagueCardContainer}>
                    {leagues
                      .filter((league) =>
                        leagueTypeFilter.includes(league.settings.leagueType)
                      )
                      .map((league) => (
                        <LeagueCard league={league} key={league.id} />
                      ))}
                  </div>
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};
export default Leagues;
