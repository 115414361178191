import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import Row from "components/ui/layout/Row";
import { motion } from "framer-motion";
import {
  PlayerUser,
  Player,
  LockerUser,
  FacilityInstalledUnityGames,
} from "Models";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { RoundType } from "types/enums";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { customStyles } from "utils/ui/uiHelpers";
import FormattedUserSearchOption from "./FormattedUserSearchOption";
import QuickPlayerMenu from "./QuickPlayerMenu";
import RangeSpecificForm from "./RangeSpecificForm";
import RoundSpecificForm from "./RoundSpecificForm";
import TimerSelection from "./TimerSelection";
import tailwind from "./StartAdHocRoundContainer.styles";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortStringArrayByProperty } from "utils/arrays/arrayHelpers";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import { debouncedLoadOptions } from "utils/userSearch/userSearchHelpers";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { FaPlus, FaMinus } from "react-icons/fa";
import { SendRoundRangeToSimInfo } from "Messages";
import axios from "axios";
import CreateLeaugeRosterLockerUser from "components/leagues/rounds/teams/CreateLeaugeRosterLockerUser";

const StartGameForm: React.FC<StartGameFormProps> = (props) => {
  const { unityGames } = useFlags();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const { control, formState, watch, setValue } = useFormContext();
  const {
    simulatorReservation,
    simulatorId,
    queueItem,
    callback,
    allowCreateLockerUser,
  } = props;

  const [expandLockerUserMenu, setExpandLockerUserMenu] = useState(false);
  const [expandQuickPlayerMenu, setExpandQuickPlayerMenu] = useState(false);
  const [defaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );
  const [
    facilityUnityGames,
    setFacilityUnityGames,
  ] = useState<FacilityInstalledUnityGames>({
    facilityId: "",
    simulators: [
      {
        simulatorId: "",
        games: [],
      },
    ],
  });
  const installedSimulatorUnityGames =
    facilityUnityGames?.simulators.find(
      (simulator) => simulator?.simulatorId === simulatorId
    )?.games || [];

  const watchGameType = watch("roundType");

  const handlePlayerChange = (options: any) => {
    callback && callback(options);
    setValue("roundInfo.players", options);
  };

  useEffect(() => {
    if (simulatorReservation) {
      setValue("roundType", RoundType.ROUND);
    }
  }, [simulatorReservation, setValue]);

  useEffect(() => {
    async function getInstalledUnityGames() {
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/unity/facility/${currentFacilityId}/installed_games`;
      const { data } = await axios.get<FacilityInstalledUnityGames>(
        url,
        leagueQueryDefaultOptions()
      );

      setFacilityUnityGames(data || []);
    }

    !facilityUnityGames.simulators[0].games.length &&
      simulatorId &&
      getInstalledUnityGames();
  }, [
    currentFacilityId,
    facilityUnityGames,
    facilityUnityGames.simulators,
    simulatorId,
    watch,
  ]);

  return (
    <div className="mb-4">
      <Row cssClasses="mt-6">
        <span className={`${tailwind.span}`}>Add Players </span>
      </Row>
      <Row cssClasses="mt-2">
        <Controller
          name="roundInfo.players"
          rules={{
            validate: {
              required: () => {
                if (watch("roundInfo.game")) {
                  if (
                    installedSimulatorUnityGames.find(
                      (game) => game.unityGameId === watch("roundInfo.game")
                    )?.doesntUsePlayers === false &&
                    watch("roundInfo.players")?.length === 0
                  )
                    return "At least one player is required";
                } else {
                  if (watch("roundInfo.players")?.length === 0)
                    return "At least one player is required";
                }
              },
            },
          }}
          control={control}
          defaultValue={props.players || []}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              isMulti
              backspaceRemovesValue={false}
              noOptionsMessage={() =>
                "No players found. Try another search or click 'Add Quick Player' to create a new player."
              }
              loadOptions={(query) => debouncedLoadOptions(query)}
              getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
              cacheOptions
              formatOptionLabel={(option, { context }) => {
                return (
                  <FormattedUserSearchOption value={option} context={context} />
                );
              }}
              data-testid="roundInfo.players"
              styles={customStyles}
              placeholder={"Start typing to search..."}
              id="startGameAddPlayers"
              inputId="startGameAddPlayers"
              onChange={(options) => handlePlayerChange(options)}
              className="dark:text-white"
            />
          )}
        />
        <small
          className={`pt-2 text-gray-400 dark:text-gray-200 text-xs inline-block`}
        >
          Search for players by name, email, phone number or Locker username. To
          create and add a new player click 'Add Quick Player'.
        </small>
      </Row>
      <Row cssClasses="mt-2">
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.players"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </Row>
      <Row cssClasses="flex-row py-4 mb-2">
        {allowCreateLockerUser && (
          <span
            data-testid="createLockerUserLink"
            onClick={() => {
              setExpandLockerUserMenu(!expandLockerUserMenu);
              setExpandQuickPlayerMenu(false);
            }}
            className={`hover:cursor-pointer text-xs px-4 py-2 poppins rounded-xl ${
              expandLockerUserMenu
                ? " bg-white dark:bg-zinc-600 border-gray-300 border text-black dark:text-white"
                : " bg-brandGreen dark:bg-brandGreen hover:bg-brandYellow text-white hover:text-black dark:text-black"
            } `}
          >
            {expandLockerUserMenu ? (
              <FaMinus className="text-black w-3 h-3 pb-0.5" />
            ) : (
              <FaPlus className=" w-3 h-3 pb-0.5" />
            )}
            <span className={`${tailwind.quickPlayer} pr-2 text-xs`}>
              Create Locker User
            </span>{" "}
          </span>
        )}
        <span
          data-testid="quickPlayerLink"
          onClick={() => {
            setExpandLockerUserMenu(false);
            setExpandQuickPlayerMenu(!expandQuickPlayerMenu);
          }}
          className={`hover:cursor-pointer text-xs px-4 py-2 poppins rounded-lg ${
            expandQuickPlayerMenu
              ? " bg-white dark:bg-zinc-600 border-gray-300 border text-black dark:text-white ml-2 rounded-xl"
              : ` hover:border-black text-gray-500 text-normal border border-gray-200 rounded-xl dark:text-white ${
                  allowCreateLockerUser ? "ml-2" : "ml-2"
                } `
          } `}
        >
          {expandQuickPlayerMenu ? (
            <FaMinus className="text-black w-3 h-3 pb-0.5" />
          ) : (
            <FaPlus className="text-gray-400 w-3 h-3 pb-0.5" />
          )}
          <span className={`${tailwind.quickPlayer} pr-2 text-xs`}>
            Add Quick Player
          </span>{" "}
        </span>
        {expandQuickPlayerMenu && (
          <div className="negativeMarginOutside">
            <motion.div className="border-white pb-2 bg-gray-100 dark:bg-zinc-600 negativeMarginInside">
              <QuickPlayerMenu
                setExpandQuickPlayerMenu={setExpandQuickPlayerMenu}
              />
            </motion.div>
          </div>
        )}
        {allowCreateLockerUser && expandLockerUserMenu && (
          <div className="negativeMarginOutside">
            <motion.div className="px-4 py-4 border-white bg-gray-100 dark:bg-zinc-600 mt-6 negativeMarginInside">
              <CreateLeaugeRosterLockerUser
                showHideComponentCallback={setExpandLockerUserMenu}
                returnGuidOnly={false}
                returnLockerUserGuidCallback={(player: LockerUser) => {
                  handlePlayerChange([
                    ...watch("roundInfo.players"),
                    {
                      displayName: player.firstName + " " + player.lastName,
                      email: player.email,
                      userGuid: player.userId,
                      userName: player.userName,
                      phoneNumber: player.phoneNumber,
                    },
                  ]);
                }}
              />
            </motion.div>
          </div>
        )}
      </Row>
      <FormRow>
        <span className={tailwind.span}>Select Round, Range or Game</span>
        <small className={tailwind.small}>
          Will this round be for a round, range or ball striking game?
        </small>
      </FormRow>
      <Row cssClasses="pt-4">
        <Controller
          name="roundType"
          rules={{ required: "Game type is required" }}
          control={control}
          render={({ field }) => (
            <Select
              isSearchable={false}
              inputRef={field.ref}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.gameType.filter(
                (gameType) => {
                  if (unityGames) {
                    return true;
                  } else {
                    if (gameType.value === RoundType.GAME) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              )}
              value={roundConfigEnumsAsReactSelectOptions.gameType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundType"
          render={({ message }) => (
            <div className={tailwind.errorMsg}>{message}</div>
          )}
        />
      </Row>
      {watchGameType === RoundType.GAME && (
        <>
          <FormRow>
            <span className={tailwind.span}>Select Game Type</span>
          </FormRow>
          <FormRow>
            <Controller
              name="roundInfo.gameFilter"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={[
                    { label: "All Games", value: "" },
                    { label: "Ball Striking Games", value: "Ball Striking" },
                    { label: "Bar and Arcade Games", value: "Bar and Arcade" },
                    { label: "Mini Golf Games", value: "Mini Golf" },
                    { label: "Multi-Sport Games", value: "Multi-Sport" },
                  ]}
                  value={roundConfigEnumsAsReactSelectOptions.unityGames.find(
                    (c) => c.value === field.value
                  )}
                  onChange={(val: { label: string; value: string }) => {
                    field.onChange(val.value);
                    setValue("roundInfo.game", "");
                  }}
                  styles={customStyles}
                />
              )}
            />
          </FormRow>
          <FormRow>
            <span className={tailwind.span}>Select Game</span>
          </FormRow>
          <FormRow>
            <Controller
              name="roundInfo.game"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={sortStringArrayByProperty(
                    installedSimulatorUnityGames,
                    "name",
                    true
                  )
                    .filter((game) =>
                      game.gameTypes.some((gameType) =>
                        gameType.name?.includes(watch("roundInfo.gameFilter"))
                      )
                    )
                    .map((game) => ({
                      label: game.name,
                      value: game.unityGameId,
                    }))}
                  value={installedSimulatorUnityGames
                    .filter((c) => c.unityGameId === field.value)
                    .map((game) => ({
                      label: game.name,
                      value: game.unityGameId,
                    }))}
                  onChange={(val: { label: string; value: string }) =>
                    field.onChange(val.value)
                  }
                  styles={customStyles}
                />
              )}
            />
          </FormRow>
          {watch("roundInfo.game") &&
            facilityUnityGames.simulators[0].games.find(
              (game) => game.unityGameId === watch("roundInfo.game")
            )?.optionalMultiSport && (
              <FormRow>
                <Controller
                  name="roundInfo.multisport"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="checkbox"
                      value="true"
                      className="mr-2 rounded border border-gray-400 text-brandGreen focus:ring-brandGreen"
                    />
                  )}
                />
                <div className="inline pt-1 text-xs poppins">
                  Use multisport camera
                </div>
              </FormRow>
            )}
        </>
      )}
      {watchGameType === RoundType.RANGE ? <RangeSpecificForm /> : null}
      {!simulatorReservation &&
      !queueItem &&
      watchGameType !== (null || undefined) ? (
        <>
          <div className="bg-gray-50 dark:bg-zinc-800 -mx-12 px-12 mt-6 pb-6">
            <FormRow>
              <span className={tailwind.span}>Set the Timer</span>
              <small className={tailwind.small}>
                {`How many minutes do you want for the ${RoundType[
                  watchGameType
                ]?.toLowerCase()} timer?`}
              </small>
            </FormRow>
            <FormRow>
              <TimerSelection />
            </FormRow>
          </div>
        </>
      ) : null}
      {watchGameType === RoundType.ROUND ? (
        <>
          <FormRow>
            <span className={tailwind.span}>Select Course Options</span>
          </FormRow>
          <RoundSpecificForm
            simulatorId={simulatorReservation?.simulatorId || simulatorId}
            defaultRoundSettings={defaultRoundSettings}
          />
        </>
      ) : null}
    </div>
  );
};
export default StartGameForm;

type StartGameFormProps = {
  simulatorReservation?: SendRoundRangeToSimInfo;
  simulatorId?: string;
  queueItem?: boolean;
  callback?: React.Dispatch<React.SetStateAction<any>>;
  players?: Player[];
  allowCreateLockerUser?: boolean;
};
