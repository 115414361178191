import { styles } from "./Leagues.styes";
import { Controller, useFormContext } from "react-hook-form";
import { ScorecardMatchResponseDto } from "ScorecardModels";
import { showMatchWinnerBadge } from "modules/leagues/leagueHelpers";
import { MatchStatus } from "types/enums";

type LeagueRankedTeamScorecardProps = {
  callback?: any;
  matchResults: ScorecardMatchResponseDto;
  showNetScore?: boolean;
  addPointsToRound?: boolean;
  index?: number;
};

const RoundMatchPlayScorecard: React.FC<LeagueRankedTeamScorecardProps> = (
  props
) => {
  const { matchResults, showNetScore, addPointsToRound, index } = props;
  const { control } = useFormContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
    }
  };

  const winningParticipant = showNetScore
    ? matchResults?.netMatchWinner?.winningParticipant
    : matchResults?.grossMatchWinner?.winningParticipant;

  return (
    <div
      className={`w-full font-bold bg-gray-100 dark:bg-zinc-800 pt-1 items-center`}
    >
      <div key={matchResults?.id} className={styles.modalResultsRow}>
        <div className="col-span-4 font-bold poppins ml-2">
          {matchResults?.name}
        </div>
        <div className="hidden md:inline-block col-span-2">
          {matchResults?.matchStatus > MatchStatus.PLAYING && (
            <span
              className={`poppins rounded-full px-3 py-1.5 text-xs ${showMatchWinnerBadge(
                winningParticipant
              )}`}
            >
              {winningParticipant === 0 ? "Tied" : "Wins"}
            </span>
          )}
        </div>
        <div className="hidden md:inline-block col-span-2"></div>

        {addPointsToRound ? (
          <div className="col-span-1">
            <Controller
              name={`teams.[${index}].points`}
              control={control}
              rules={{
                required: "Please enter a point value",
              }}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min={0}
                  max={100}
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              )}
            />
            <Controller
              name={`teams.[${index}].teamLeaderboardId`}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
          </div>
        ) : (
          <div className="col-span-1 pl-2">
            <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1"></span>
          </div>
        )}

        <div className="col-span-1"></div>
        <div className="col-span-1 items-center text-right pr-2"></div>
      </div>
    </div>
  );
};

export default RoundMatchPlayScorecard;
