import { useState } from "react";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { FiPrinter } from "react-icons/fi";
import { CgSpinner } from "react-icons/cg";
import { ImWarning } from "react-icons/im";
import { styles } from "./Scorecard.styles";
import { ProblemDetailsException } from "Models";
import { useToasterStore } from "react-hot-toast";
import { MatchResults, PlayerScorecardDto } from "ScorecardModels";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useIsLeagueLocation } from "modules/leagues/leagueHelpers";
import {
  useAddScorecardToRound,
  useRankLeagueRoundScorecards,
} from "modules/leagues/leagueScorecardHooks";
import {
  useSelectedLeagueId,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueRoundHooks";
import {
  ApiExceptionClassType,
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import ScorecardHolesLayout from "./ScorecardHolesLayout";
import { HandicapMode, ScoringType } from "types/enums";

type ScorecardDetailsProps = {
  playerScorecard: PlayerScorecardDto[];
  saveGameId: number;
  leagueScorecards?: boolean;
  scoringType?: number;
  roundFinished?: boolean;
  matchResults?: MatchResults | undefined;
  handicapMode?: HandicapMode;
};

const ScorecardDetails: React.FC<ScorecardDetailsProps> = (props) => {
  const {
    playerScorecard,
    saveGameId,
    roundFinished,
    matchResults,
    scoringType,
    handicapMode,
  } = props;

  let showAddToScorecard = useIsLeagueLocation();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeagueId = useSelectedLeagueId();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;

  const isLeagueScorecardTrayOpen = useSelector(
    (state: RootState) => state.nav.leagueScorecardTray?.isOpen
  );

  const [allowUnfinishedRounds, setAllowUnfinishedRounds] = useState(false);

  const [
    addScorecardToRoundQueryState,
    addScorecardToRound,
  ] = useAddScorecardToRound(
    currentFacilityId,
    selectedLeagueId,
    selectedLeagueRoundId
  );

  const [, , refreshLeagueRoundRankedScorecards] = useRankLeagueRoundScorecards(
    currentFacilityId,
    selectedLeagueId,
    selectedLeagueRoundId
  );

  const handleAddToLeaderboardOnClick = async () => {
    let response = addScorecardToRound({
      savegameIds: [saveGameId],
      allowUnfinishedRounds: allowUnfinishedRounds,
    });

    response?.then(async (res) => {
      if (isApiStatusSuccess(res?.status)) {
        await showCustomNotification(
          "Scorecard added to leaderboard",
          GenericNotificationType.SUCCESS
        );
        setAllowUnfinishedRounds(false);
      } else {
        response?.then((res) => {
          let errorMessage = res.body as ProblemDetailsException;

          if (
            errorMessage.extensions.exceptionClass ===
            ApiExceptionClassType.SCORECARD_NOT_FINALIZED
          ) {
            setAllowUnfinishedRounds(true);
          }
        });

        if (!toastIsActive) {
          showCustomNotification(
            getApiResponseErrorMessage((await response)?.body),
            GenericNotificationType.ERROR
          );
        }
      }

      refreshLeagueRoundRankedScorecards();
    });
  };

  return (
    <table className={styles.scorecardTable}>
      <tbody>
        <tr>
          <td colSpan={2}>
            <div className="flex justify-between">
              <div className="flex flex-row"></div>
              <div className="flex flex-col">
                {showAddToScorecard && isLeagueScorecardTrayOpen && (
                  <button
                    className={`${styles.addToLeaderboardButton} float-right`}
                    onClick={() => handleAddToLeaderboardOnClick()}
                  >
                    {addScorecardToRoundQueryState.isPending ? (
                      <>
                        <CgSpinner className="size-4 animate-spin" /> Adding to
                        Leaderboard...
                      </>
                    ) : allowUnfinishedRounds ? (
                      "Add Unfinished Round to Leaderboard"
                    ) : (
                      "Add to Leaderboard"
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="flex flex-row mt-2 px-4">
              <div className="flex w-1/2 text-right justify-end">
                {!roundFinished && isLeagueScorecardTrayOpen && (
                  <span className="text-xs text-gray-400">
                    <ImWarning className="size-4" /> Scorecards are still
                    processing
                  </span>
                )}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td colSpan={2} className="pt-4">
            {playerScorecard[0]?.holes.length > 0 ? (
              <ScorecardHolesLayout
                playerScorecard={playerScorecard}
                matchResults={matchResults}
                scrambleLeague={
                  scoringType && scoringType === ScoringType.SCRAMBLE
                    ? true
                    : false
                }
                handicapMode={handicapMode}
                showNetScore={handicapMode === HandicapMode.OFF ? false : true}
              />
            ) : (
              <div className="text-center">
                <span className="text-normal text-gray-700">
                  No scorecard data available
                </span>
              </div>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan={2} className="align-right hidden">
            <button
              className={styles.scorecardPrintButton}
              onClick={window.print}
            >
              <FiPrinter /> Print
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default ScorecardDetails;
